@import "mixins"
//modals
.signupModal :global(.modal),
.loginModal :global(.modal),
.inactivityModal :global(.modal)
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)
  border-radius: 8px

  > i
    border-top-right-radius: 6px
    width: 23px
    height: 24px
    padding-left: 2px
    padding-bottom: 2px
    background: var(--login_closeBtnBg)

  :global(.loader) span
    font-size: 18px
    width: 18px
    height: 18px

  &:global

    &:has(.country-list)
      > div
        overflow: visible!important

    &:has(.country-list.hide)
      > div
        overflow: hidden!important

.loginModal :global(.modal),
.inactivityModal :global(.modal)
  width: 290px
  border-radius: 8px
  // min-height: 284px
  background: var(--login_bg)
  overflow: hidden

.inactivityModal :global(.modal)
  display: flex
  flex-direction: column
  padding: 30px 16px 20px
  > p
    margin: auto 0
    line-height: 18px
    text-align: center
    font-size: 13px
    color: rgb(var(--secondaryColor))
  > div
    @include flex
    justify-content: center
    margin-top: 20px
    > button
      flex: 1
      height: 26px
      text-transform: uppercase
      &:nth-child(2)
        margin-left: 12px
