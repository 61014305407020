@import "mixins"
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video
  margin: 0
  padding: 0
  border: 0
  font-family: Arial
  font-size: 100%
  vertical-align: baseline

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section
  display: block

html,
body
  width: 100%
  height: 100%
  font-size: 12px

body
  line-height: 1

ol,
ul
  list-style: none

blockquote,
q
  quotes: none

blockquote:before,
blockquote:after,
q:before,
q:after
  content: ""

table
  border-collapse: collapse
  border-spacing: 0

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"]
  outline: none
  -webkit-appearance: none
  font-family: Arial

button,
input[type="submit"],
input[type="button"]
  outline: none
  @include pointer
  -webkit-appearance: none

a
  color: inherit
  text-decoration: none

input[type="submit"]:disabled
  opacity: 0.5
  cursor: default

html,
body
  width: 100%
  height: 100%
  margin: 0
  padding: 0

*
  outline: none
  box-sizing: border-box
  image-rendering: crisp-edges

input:not([type="checkbox"])
  font-family: Arial
  outline: none
  -webkit-appearance: none

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration,
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  display: none