@import "mixins"
.modalContainer
  width: 100%
  height: 100%
  position: fixed
  z-index: 1000
  top: 0
  left: 0
  right: 0
  bottom: 0
  @include flex
  justify-content: center

  :global(.modal)
    position: absolute

.modalBackdrop
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: var(--modal_backdropBg)

.closeModal
  background: var(--modal_closeBg)
  border-bottom-left-radius: 15px
  border-top-right-radius: 15px
  @include flex
  justify-content: center
  width: 26px
  height: 30px
  position: absolute
  top: 0
  right: 0
  z-index: 2

  &:before
    @include closeIcon

  &:hover
    @include pointer

    &:before
      opacity: 1