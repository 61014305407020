@import "mixins"
body
  --icon-casino-play: url("https://inplay.tech/assets/common/desktop/base-icons/casino-play.webp")
  --icon-favorite: url("https://inplay.tech/assets/common/desktop/base-icons/favorite.svg")
  --icon-multiview-selected: url("https://inplay.tech/assets/common/desktop/base-icons/multiview-selected.svg")
  --icon-bet-on-btc-bg: url("https://inplay.tech/assets/common/desktop/betonbtc/bet-on-btc-bg.webp")
  --icon-bet-on-btc-basketball: url("https://inplay.tech/assets/common/desktop/betonbtc/bet-on-btc-basketball.webp")
  --icon-bet-on-btc-rules-coin: url("https://inplay.tech/assets/common/desktop/betonbtc/bet-on-btc-rules-coin.webp")
  --icon-esport-default: url("https://inplay.tech/assets/common/desktop/live-scoreboards/esport-default.webp")
  --icon-esport-default-flat: url("https://inplay.tech/assets/common/desktop/live-scoreboards-flat/esport-default.webp")
  --icon-not-found-red-bubble: url("https://inplay.tech/assets/common/desktop/not-found/red-bubble.webp")
  --icon-search: url("https://inplay.tech/assets/common/desktop/base-icons/account-search.svg")
  --icon-close: url("https://inplay.tech/assets/common/desktop/base-icons/close-white.svg")
  --icon-button-save: url("https://inplay.tech/assets/common/desktop/base-icons/account-save.svg")
  --icon-button-cancel: url("https://inplay.tech/assets/common/desktop/base-icons/account-cancel.svg")
  --icon-button-reset: url("https://inplay.tech/assets/common/desktop/base-icons/account-reset.svg")
  --icon-button-edit: url("https://inplay.tech/assets/common/desktop/base-icons/account-edit.svg")
  --icon-dropdown-arrow: url("https://inplay.tech/assets/common/desktop/base-icons/dropdown-arrow.svg")
  --icon-password-show: url("https://inplay.tech/assets/common/desktop/base-icons/password-show.svg")
  --icon-password-hide: url("https://inplay.tech/assets/common/desktop/base-icons/password-notshow.svg")
  --icon-checkbox-check: url("https://inplay.tech/assets/common/desktop/base-icons/checkbox-checked-icon.svg")
  --icon-casino-compact-search: url("https://inplay.tech/assets/common/casino/compact/search.svg")
  --icon-dark-mode-moon: url("https://inplay.tech/assets/common/desktop/base-icons/dark-mode-moon.svg")
  --icon-increased: url("https://inplay.tech/assets/common/desktop/base-icons/increased.png")
  --icon-increased-active: url("https://inplay.tech/assets/common/desktop/base-icons/increased-active.png")
  --icon-decreased: url("https://inplay.tech/assets/common/desktop/base-icons/decreased.png")
  --icon-decreased-active: url("https://inplay.tech/assets/common/desktop/base-icons/decreased-active.png")
  --icon-notification-bg: url("https://inplay.tech/assets/common/notification-image.webp")
  --icon-wallet-tab-corner: url("https://inplay.tech/assets/common/wallet/tab-corner.svg")

  --specialFont: "helveticaNeue"
  --specialFontBoldCondensed: "helveticaNeueBoldCondensed"
  --specialFontCurrency: "currency"
  --common_noDataColor: rgba(#21262c, 0.6)
  --icon_base_fill: rgb(var(--primaryColor))
  --field_borderColor: transparent
  --common_field_labelColor: rgb(var(--primaryColor))
  --common_field_color: rgb(var(--secondaryColor))
  --common_field-iconFill: #21262c
  --checkbox_bg: rgb(var(--secondaryColor))
  --checkbox_checked_fill: #21262c
  --checkbox_shadow: rgba(var(--secondaryColor), 0.3)
  --checkbox_border: #bbc0cb
  --phoneInput_countryListItemBorder: var(--login_countryListBorder)
  --phoneInput_countryListBg: var(--login_countryListBg)
  --loader_color: rgba(var(--secondaryColor), 0.6)
  --common_dropdown_placeholderColor: rgba(var(--primaryColor), .5)
  --common_dropdown_listBg: var(--customDdl_bg)
  --common_dropdown_listBorder: var(--customDdl_borderTop)
  --common_dropdown_listBorderBottom: var(--customDdl_borderBottom)
  --common_dropdown_listLiHoverBg: rgb(var(--customDdl_activeBg))
  --common_verification-inputBg: var(--field_bg)
  --common_verification-inputColor: rgba(var(--secondaryColor))
  --common_verification-inputBorder: var(--field_bg)
  --common_verification-inputFocusBg: transparent
  --common_verification-requestCodeBtnBg: rgb(var(--secondaryColor))
  --common_verification-requestCodeBtnHoverBg: #e7eefa
  --common_verification-requestCodeBtnActiveBg: #d8e1f2
  --common_verification-requestCodeBtnColor: #21262c

.light-theme
  --common_account-scrollBg: #e6e9ef
  --loader_color: rgba(#21262c, 0.6)
  --icon_base_fill: #21262c
  --field_borderColor: rgb(var(--primaryColor))
  --common_field_labelColor: rgba(#21262c, 0.6)
  --common_field_color: #21262c
  --phoneInput_countryListItemBorder: #d9dfe5
  --phoneInput_countryListBg: rgb(var(--secondaryColor))
  --common_dropdown_placeholderColor: rgba(#21262c, 0.6)
  --common_dropdown_listBg: rgb(var(--secondaryColor))
  --common_dropdown_listBorder: rgb(var(--secondaryColor))
  --common_dropdown_listBorderBottom: #D9DEE3
  --common_dropdown_listLiHoverBg: #f1f3f6
  --common_dropdown_2stepAuthLabelColor: var(--common_dropdown_placeholderColor)
  --common_verification-inputBg: #e7eaf0
  --common_verification-inputColor: var(--common_field_color)
  --common_verification-inputBorder: rgb(var(--primaryColor))
  --common_verification-inputFocusBg: rgb(var(--secondaryColor))
  --common_verification-requestCodeBtnBg: rgb(var(--particularColor))
  --common_verification-requestCodeBtnHoverBg: var(--button_secondaryHoverBg)
  --common_verification-requestCodeBtnActiveBg: var(--button_secondaryActiveBg)
  --common_verification-requestCodeBtnColor: rgb(var(--secondaryColor))

  --common_account-menuItemActiveColor: #000
  --common_account-contentHeaderBg: #e6e9ef
  --common_account-contentShadow: 0 0 4px rgba(0, 0, 0, 0.5)
  --common_account-contentBg: rgb(var(--secondaryColor))
  --common_account-txtColor: #21262c
  --common_account-contentTabColor: rgba(33, 38, 44, .7)
  --common_account-contentTabHoverColor: #21262c
  --common_account-contentTabActiveColor: var(--account_particularColor)
  --common_account-contentTabActiveSportIconFill: var(--account_particularColor)
  --common_account-personalDetailsBtnBg: rgb(var(--secondaryColor))
  --common_account-personalDetailsSpanColor: var(--account_particularColor)
  --common_account-personalDetailsSpanLabelColor: #21262c
  --common_account-personalDetailsSeparator: #cfd2d7
  --common_account-personalDetailsBlockBg: rgb(var(--secondaryColor))
  --common_account-personalDetailsBlockHeaderBg: #e6e9ef
  --common_account-personalDetailsVerifyPopupBg: rgb(var(--secondaryColor))
  --common_account-personalDetailsVerifyPopupHeaderBg: #e6e9ef
  --common_account-personalDetailsAddBtnColor: rgb(var(--secondaryColor))
  --common_account-personalDetailsAddBtnBg: #494f57
  --common_account-personalDetailsAddBtnHoverBg: #3f454f
  --common_account-personalDetailsAddBtnActiveBg: #353c46
  --common_account-personalDetailsPasswordConfirmMsgBg: #f8f9fa
  --common_account-personalDetailsPasswordConfirmMsgBorder: #e6e9ee
  --common_account-personalDetailsPasswordConfirmMsgColor: #42b147
  --common_account-loaderBg: transparent
  --common_account-noDataColor: #bdc1c2
  --common_account-tableBg: rgb(var(--secondaryColor))
  --common_account-tableBorder: #e6e9ef
  --common_account-tableThBg: rgb(var(--secondaryColor))
  --common_account-tableTdSecondBg: #f9f9f9
  --common_account-tableTdHoverBg: rgba(var(--particularColor), 0.06)

  --common_account-betsSportRowIdBorder: #e6e9ef
  --common_account-betsSportRowIdColor: rgb(var(--particularColor2))
  --common_account-betsStakeColumnColor: var(--account_particularColor)
  --common_account-betsSportRowOpenedBg: #f1f7fd
  --common_account-betsDetailsBg: rgb(var(--secondaryColor))
  --common_account-betsDetailsHeaderBg: #f9f9f9
  --common_account-betsDetailsSeparatorColor: #4a535f
  --common_account-toolsFieldBg: transparent
  --common_account-toolsFieldBorder: #e6e9ef
  --common_account-toolsRadioBtnCheckedBorder: rgb(var(--secondaryColor))
  --common_account-toolsRadioBtnCheckedShadow: #cdd3df

  --common_account-agentParticularColor: rgb(var(--particularColor2))
  --common_account-agentCreateBtnColor: rgb(var(--particularColor2))
  --common_account-agentCreateFieldBg: #e7eaf0
  --common_account-agentReportTabBg: rgb(var(--secondaryColor))
  --common_account-agentReportTabBorder: #edf0f4
  --common_account-agentReportTabHeaderBg: rgba(230, 233, 239, 0.7)
  --common_account-agentReportTabHeaderColor: #21262c
  --common_account-agentReportTabSelectedBorder: rgb(var(--particularColor))
  --common_account-agentReportTabSelectedHeaderColor: rgb(var(--particularColor))
  --common_account-agentReportTabSummaryBg: #e6e9ef
  --common_account-agentSportIconBg1: #e6e9ef
  --common_account-agentSportIconBg2: rgb(var(--particularColor))
  --common_account-agentTotalColor: var(--account_particularColor)
  --common_account-agentTotalInfoColor: var(--account_particularColor)
  --common_account-agentCashbackColBg: #f2f9fc
  --common_account-agentCashbackColCenterBg: #f9fdff
  --common_account-agentEditBtnFill: #566e8e
  --common_account-agentEditInputBg: rgb(var(--secondaryColor))
  --common_account-agentEditInputBorder: #cbd4da
  --common_account-agentEditInputColor: #21262c

  --common_account-bonusesGameTypeSvgBg: rgb(var(--secondaryColor))
  --common_account-referralBecomeCheckboxBorder: var(--common_account-txtColor)

  --common_account-messagesComposeBtnShadow: #e6e9ef
  --common_account-messagesComposeTitleBg: #edf0f4
  --common_account-messagesStarBg: rgba(#252525, 0.5)
  --common_account-messagesStarActiveBg: var(--account_particularColor)
  --common_account-messagesMessageBg: rgb(var(--secondaryColor))
  --common_account-messagesMessageHoverBg: #f9f9f9
  --common_account-messagesMessageNewBg: rgba(var(--particularColor2), 0.1)
  --common_account-messagesFieldBorder: rgba(var(--primaryColor), 0.5)
  --common_account-messagesResetBtnBg: #494f57
  --common_account-messagesResetBtnHoverBg: #3f454f
  --common_account-messagesResetBtnActiveBg: #353c46

  --common_accountCompact_fieldBg: #e7eaf0

.dark-theme
  --icon-no-data: var(--icon-no-data-dark)
  --icon-no-messages: var(--icon-no-messages-dark)
  --icon_base_fill: rgb(var(--secondaryColor))
  --field_borderColor: var(--account_fieldBorderColor-dark)
  --common_field_labelColor: rgba(var(--primaryColor), 0.8)
  --common_field_color: rgb(var(--secondaryColor))
  --common_field-iconFill: var(--account_fieldIconFill-dark)
  --common_dropdown_placeholderColor: rgba(var(--primaryColor), 0.7)
  --common_dropdown_listBg: var(--customDdl_bg)
  --common_dropdown_listBorder: var(--customDdl_borderTop)
  --common_dropdown_listBorderBottom: var(--customDdl_borderBottom)
  --common_dropdown_listLiHoverBg: rgb(var(--customDdl_activeBg))
  --common_dropdown_2stepAuthLabelColor: rgb(var(--secondaryColor))
  --common_noDataColor: rgba(var(--primaryColor), 0.6)
  --common_account-scrollBg: var(--account-scrollBg-dark)
  --checkbox_bg: var(--account_checkboxBg-dark)
  --checkbox_checked_fill: var(--account_checkboxCheckedFill-dark)
  --checkbox_shadow: transparent
  --checkbox_border: transparent
  --phoneInput_countryListItemBorder: var(--login_countryListBorder)
  --phoneInput_countryListBg: var(--login_countryListBg)
  --common_verification-inputBg: var(--verification_inputBg-dark)
  --common_verification-inputBorder: var(--verification_inputBorder-dark)
  --common_verification-inputFocusBg: var(--verification_inputFocusBg-dark)

  --common_account-menuItemActiveColor: rgb(var(--secondaryColor))
  --common_account-contentHeaderBg: var(--account_contentHeaderBg-dark)
  --common_account-contentBg: var(--account_contentBg-dark)
  --common_account-txtColor: rgb(var(--primaryColor))
  --common_account-contentTabColor: rgb(var(--primaryColor))
  --common_account-contentTabHoverColor: rgb(var(--secondaryColor))
  --common_account-contentTabActiveColor: rgb(var(--secondaryColor))
  --common_account-contentTabActiveSportIconFill: var(--common_account-contentHeaderBg)
  --common_account-personalDetailsBtnBg: var(--account_personalDetailsBtnBg-dark)
  --common_account-personalDetailsSpanColor: rgba(var(--primaryColor), 0.8)
  --common_account-personalDetailsSpanLabelColor: rgb(var(--secondaryColor))
  --common_account-personalDetailsSeparator: var(--account_personalDetailsSeparator-dark)
  --common_account-personalDetailsBlockBg: var(--account_personalDetailsBlockBg-dark)
  --common_account-personalDetailsBlockHeaderBg: var(--account_personalDetailsBlockHeaderBg-dark)
  --common_account-personalDetailsVerifyPopupBg: var(--account_personalDetailsVerifyPopupBg-dark)
  --common_account-personalDetailsVerifyPopupHeaderBg: var(--account_personalDetailsVerifyPopupHeaderBg-dark)
  --common_account-personalDetailsAddBtnColor: var(--account_personalDetailsAddBtnColor-dark)
  --common_account-personalDetailsAddBtnBg: var(--account_personalDetailsAddBtnBg-dark)
  --common_account-personalDetailsAddBtnHoverBg: var(--account_personalDetailsAddBtnHoverBg-dark)
  --common_account-personalDetailsAddBtnActiveBg: var(--account_personalDetailsAddBtnActiveBg-dark)
  --common_account-personalDetailsPasswordConfirmMsgBg: var(--account_personalDetailsPasswordConfirmMsgBg-dark)
  --common_account-personalDetailsPasswordConfirmMsgBorder: var(--account_personalDetailsPasswordConfirmMsgBorder-dark)
  --common_account-personalDetailsPasswordConfirmMsgColor: var(--account_personalDetailsPasswordConfirmMsgColor-dark)
  --common_account-loaderBg: var(--account_loaderBg-dark)
  --common_account-noDataColor: #484f56
  --common_account-tableBg: var(--account_tableBg-dark)
  --common_account-tableBorder: var(--account_tableBorder-dark)
  --common_account-tableThBg: var(--account_tableThBg-dark)
  --common_account-tableTdSecondBg: var(--account_tableTdSecondBg-dark)
  --common_account-tableTdHoverBg: var(--account_tableTdHoverBg-dark)

  --common_account-betsSportRowIdBorder: var(--account_betsSportRowIdBorder-dark)
  --common_account-betsSportRowIdColor: var(--account_betsSportRowIdColor-dark)
  --common_account-betsStakeColumnColor: rgb(var(--secondaryColor))
  --common_account-betsSportRowOpenedBg: var(--account_tableTdHoverBg-dark)
  --common_account-betsDetailsBg: var(--account_tableTdSecondBg-dark)
  --common_account-betsDetailsHeaderBg: var(--account_tableThBg-dark)
  --common_account-betsDetailsSeparatorColor: var(--account_betsDetailsSeparatorColor-dark)
  --common_account-toolsFieldBg: var(--account_toolsFieldBg-dark)
  --common_account-toolsFieldBorder: var(--account_toolsFieldBg-dark)
  --common_account-toolsRadioBtnCheckedBorder: var(--account_particularColor)
  --common_account-toolsRadioBtnCheckedShadow: var(--account_particularColor)

  --common_account-agentParticularColor: var(--account_agentParticularColor)
  --common_account-agentCreateBtnColor: var(--account_agentCreateBtnColor-dark)
  --common_account-agentCreateFieldBg: var(--account_agentCreateFieldBg-dark)
  --common_account-agentReportTabBg: var(--account_agentReportTabBg-dark)
  --common_account-agentReportTabBorder: var(--account_agentReportTabBorder-dark)
  --common_account-agentReportTabHeaderBg: var(--account_agentReportTabHeaderBg-dark)
  --common_account-agentReportTabHeaderColor: rgb(var(--secondaryColor))
  --common_account-agentReportTabSelectedBorder: var(--account_agentReportTabSelectedBorder-dark)
  --common_account-agentReportTabSelectedHeaderColor: var(--account_agentReportTabSelectedHeaderColor-dark)
  --common_account-agentReportTabSummaryBg: var(--account_agentReportTabSummaryBg-dark)
  --common_account-agentSportIconBg1: var(--common_account-agentReportTabSelectedHeaderColor)
  --common_account-agentSportIconBg2: var(--account_agentReportTabSummaryBg-dark)
  --common_account-agentTotalColor: var(--account_agentTotalColor-dark)
  --common_account-agentTotalInfoColor: var(--account_agentTotalInfoColor-dark)
  --common_account-agentCashbackColBg: var(--account_agentCashbackColBg-dark)
  --common_account-agentCashbackColCenterBg: var(--account_agentCashbackColCenterBg-dark)
  --common_account-agentEditBtnFill: rgb(var(--primaryColor))
  --common_account-agentEditInputBg: var(--account_agentEditInputBg-dark)
  --common_account-agentEditInputBorder: var(--account_agentEditInputBorder-dark)
  --common_account-agentEditInputColor: var(--account_agentEditInputColor-dark)

  --common_account-bonusesGameTypeSvgBg: var(--common_account-txtColor)
  --common_account-referralBecomeCheckboxBorder: var(--checkbox_bg)

  --common_account-messagesComposeBtnShadow: var(--account_contentHeaderBg-dark)
  --common_account-messagesComposeTitleBg: var(--account_messagesComposeTitleBg-dark)
  --common_account-messagesStarBg: var(--checkbox_bg)
  --common_account-messagesStarActiveBg: var(--account_messagesStarActiveBg-dark)
  --common_account-messagesMessageBg: var(--account_messagesMessageBg-dark)
  --common_account-messagesMessageHoverBg: var(--account_messagesMessageHoverBg-dark)
  --common_account-messagesMessageNewBg: var(--account_messagesMessageNewBg-dark)
  --common_account-messagesFieldBorder: var(--account_messagesComposeTitleBg-dark)
  --common_account-messagesResetBtnBg: var(--account_resetBtnBg-dark)
  --common_account-messagesResetBtnHoverBg: var(--account_resetBtnHoverBg-dark)
  --common_account-messagesResetBtnActiveBg: var(--account_resetBtnActiveBg-dark)

  --common_accountCompact_fieldBg: var(--accountCompact_fieldBg)
